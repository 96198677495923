<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <a
                @click="emit('pageChanged', 'SlotSelector')"
                href="#"
                class="back-btn rounded"
            >
                <i class="fa fa-chevron-left"></i>
            </a>
            <h1 class="m-0 text-light">
                {{ t('pages.bookNow.bookingDetails.title') }}
            </h1>
        </div>
        <div class="card p-3 shadow-sm mb-3">
            <p class="mb-1">
                {{ t('pages.bookNow.slotSelector.availableFrom') }}
            </p>
            <BookingTimeline class="mb-3" :booking="currentBooking" />
            <div v-if="currentYacht">
                <p style="font-weight: 500" class="mb-1">
                    {{ t('pages.bookNow.bookingDetails.conditionsFor') }}
                </p>
                {{
                    t('pages.bookNow.bookingDetails.cancelDeadline', {
                        hours: bookingSettings.cancellation_deadline_hours,
                    })
                }}
                {{ t('misc.hours') }}
                <br />
                <span
                    v-if="
                        bookingSettings.maximum_length_hours != null &&
                        bookingSettings.maximum_length_hours != ''
                    "
                >
                    {{ t('pages.bookNow.slotSelector.durationBetween') }}
                    <span v-if="bookingSettings.minimum_length_hours == 0"
                        >{{ t('pages.bookNow.slotSelector.oneHour') }}
                        {{ t('pages.bookNow.slotSelector.to') }}
                        {{
                            convertToDaysOrHours(
                                bookingSettings.maximum_length_hours
                            )
                        }}</span
                    >
                    <span v-else>
                        {{
                            convertToDaysOrHours(
                                bookingSettings.minimum_length_hours
                            )
                        }}
                        {{ t('pages.bookNow.slotSelector.to') }}
                        {{
                            convertToDaysOrHours(
                                bookingSettings.maximum_length_hours
                            )
                        }}
                    </span>
                </span>
                <span v-else>
                    {{ t('pages.bookNow.bookingDetails.moreThan') }}
                    {{
                        convertToDaysOrHours(
                            bookingSettings.minimum_length_hours
                        )
                    }}
                </span>
                <!-- </div> -->
            </div>
        </div>
        <div class="card p-3 shadow-sm mb-3">
            <h5 class="mb-2">
                {{ t('pages.bookNow.bookingDetails.checkIn') }}
            </h5>
            <div class="row">
                <div class="col">
                    <label for="checkInDate" class="form-label">{{
                        t('pages.bookNow.bookingDetails.date')
                    }}</label>
                    <!-- <select
                        v-model="checkInIndex"
                        id="checkInDate"
                        class="form-select"
                    >
                        <option
                            :value="date.index"
                            v-for="date in currentBooking.range"
                            :key="date.index"
                        >
                            {{ moment(date.date).format('ddd Do MMMM') }}
                        </option>
                    </select> -->
                    <div
                        class="w-100 d-flex align-items-center"
                        style="height: 38px"
                    >
                        <div>
                            {{
                                moment
                                    .utc(currentBooking.start.date)
                                    .format('ddd Do MMMM')
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <label for="checkInHour" class="form-label">{{
                        t('pages.bookNow.bookingDetails.hour')
                    }}</label>
                    <select
                        v-model="times.checkIn.hour"
                        id="checkInHour"
                        class="form-select"
                    >
                        <option
                            v-for="(n, index) in 24"
                            :key="index"
                            :value="index"
                        >
                            {{ String(index).padStart(2, '0') }}:00
                        </option>
                    </select>
                </div>
            </div>
            <h5 class="mb-2 mt-4">
                {{ t('pages.bookNow.bookingDetails.checkOut') }}
            </h5>
            <div class="row mb-4">
                <div class="col">
                    <label for="checkOutDate" class="form-label">{{
                        t('pages.bookNow.bookingDetails.date')
                    }}</label>
                    <!-- <select
                        v-model="checkOutIndex"
                        id="checkOutDate"
                        class="form-select"
                    >
                        <option
                            :value="date.index"
                            v-for="date in checkOutRange"
                            :key="date.index"
                        >
                            {{ moment(date.date).format('ddd Do MMMM') }}
                        </option>
                    </select> -->
                    <div
                        class="w-100 d-flex align-items-center"
                        style="height: 38px"
                    >
                        <div>
                            {{
                                moment
                                    .utc(currentBooking.end.date)
                                    .format('ddd Do MMMM')
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <label for="checkOutHour" class="form-label">{{
                        t('pages.bookNow.bookingDetails.hour')
                    }}</label>
                    <select
                        v-model="times.checkOut.hour"
                        id="checkOutHour"
                        class="form-select"
                    >
                        <option
                            v-for="(n, index) in 24"
                            :key="index"
                            :value="index"
                        >
                            {{ String(index).padStart(2, '0') }}:00
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-row" style="gap: 1rem">
                <div style="font-weight: 500">
                    {{ t('pages.bookNow.bookingDetails.duration') }}<br />
                    <div v-if="!currentAccreditation.tokens_split_monthly">
                        {{ t('pages.bookNow.bookingDetails.tokensRequired')
                        }}<br />
                        {{ t('pages.bookNow.bookingDetails.yourTokens') }}
                    </div>
                </div>
                <div>
                    <div>
                        <span v-if="durationInvalid">
                            {{ t('pages.bookNow.bookingDetails.invalid') }}
                        </span>
                        <span v-else>
                            {{ bookingLength }}
                        </span>
                    </div>
                    <div v-if="!currentAccreditation.tokens_split_monthly">
                        <div>
                            <img
                                src="@/assets/coin-icon.png"
                                style="width: 1rem"
                                class="me-1"
                            />
                            {{ tokensRequired }} {{ t('pages.bookNow.tokens') }}
                        </div>
                        <div v-if="currentAccreditation">
                            <img
                                src="@/assets/coin-icon.png"
                                style="width: 1rem"
                                class="me-1"
                            />
                            {{ currentAccreditation.current_tokens }} /
                            {{ currentAccreditation.max_tokens }}
                        </div>
                    </div>
                </div>
            </div>

            <p
                class="m-0 text-danger mt-3"
                style="font-weight: 500"
                v-if="invalid"
            >
                {{ invalidMessage }}
            </p>
        </div>
        <div
            v-if="currentAccreditation.tokens_split_monthly"
            class="card p-0 shadow-sm mb-3"
        >
            <p class="p-3 mb-1">
                {{
                    t(
                        'pages.bookNow.bookingDetails.daysAvailableAccordingToYourSubscription'
                    )
                }}
            </p>
            <table class="table m-0">
                <thead>
                    <tr style="font-size: 0.9rem">
                        <th
                            style="font-weight: 500"
                            class="text-muted text-uppercase ps-3"
                        >
                            {{ t('pages.bookNow.bookingDetails.month') }}
                        </th>
                        <th
                            style="font-weight: 500"
                            class="text-muted text-uppercase"
                        >
                            {{ t('pages.bookNow.bookingDetails.available') }}
                        </th>
                        <th
                            style="font-weight: 500"
                            class="text-muted text-uppercase pe-3"
                        >
                            {{ t('pages.bookNow.bookingDetails.required') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="ma in currentAccreditation.active_monthly_accreditations"
                        :key="'monthlyAccreditation' + ma.id"
                        :class="
                            isBeforeCurrentMonthOrNotInTheNextBookableMonths(
                                ma.month_year_timestamp
                            )
                                ? 'd-none'
                                : ''
                        "
                    >
                        <td
                            class="ps-3"
                            :class="[
                                !ma.tokensRequired ? 'text-muted' : '',
                                ma.tokensRequired > ma.current_tokens
                                    ? 'text-danger'
                                    : '',
                            ]"
                            :style="[
                                ma.tokensRequired
                                    ? 'font-weight: 500;'
                                    : 'background: whitesmoke',
                            ]"
                        >
                            {{
                                moment(ma.month_year_timestamp).format(
                                    'MMMM YYYY'
                                )
                            }}
                        </td>
                        <td
                            :class="[
                                !ma.tokensRequired ? 'text-muted' : '',
                                ma.tokensRequired > ma.current_tokens
                                    ? 'text-danger'
                                    : '',
                            ]"
                            :style="[
                                ma.tokensRequired
                                    ? 'font-weight: 500;'
                                    : 'background: whitesmoke',
                            ]"
                        >
                            {{ ma.current_tokens }} /
                            {{ ma.max_tokens }}
                        </td>
                        <td
                            class="pe-3"
                            :class="[
                                !ma.tokensRequired ? 'text-muted' : '',
                                ma.tokensRequired > ma.current_tokens
                                    ? 'text-danger'
                                    : '',
                            ]"
                            :style="[
                                ma.tokensRequired
                                    ? 'font-weight: 500;'
                                    : 'background: whitesmoke',
                            ]"
                        >
                            <div
                                class="d-flex align-items-center"
                                style="gap: 0.5rem"
                            >
                                {{ ma.tokensRequired }}

                                <i
                                    v-if="ma.tokensRequired > ma.current_tokens"
                                    class="fa fa-xmark text-danger"
                                ></i>
                                <i
                                    v-if="
                                        ma.tokensRequired <= ma.current_tokens
                                    "
                                    class="fa fa-check text-success"
                                ></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <a
            href="#"
            class="btn btn-primary w-100 mb-3"
            :class="{ disabled: invalid }"
            @click="emit('pageChanged', 'BookingConfirmation')"
        >
            {{ t('pages.bookNow.bookingDetails.next') }}
            <i style="font-size: 0.8rem" class="ms-1 fa fa-chevron-right"></i>
        </a>
    </div>
</template>
<script setup>
import {
    calculateTokensRequired,
    splitDifferentMonths,
    calculateTokensRequiredMultipleMonths,
} from '@/helpers';
import { useI18n } from 'vue-i18n';
import {
    inject,
    ref,
    toRefs,
    defineProps,
    defineEmits,
    onMounted,
    watch,
    reactive,
} from 'vue';
import BookingTimeline from '@/components/BookingTimeline';

const props = defineProps([
    'currentYacht',
    'currentBooking',
    'bookingSettings',
    'currentAccreditation',
]);

const { currentYacht, currentBooking, currentAccreditation, bookingSettings } =
    toRefs(props);
const emit = defineEmits([
    'pageChanged',
    'timesChanged',
    'setCurrentMonthlyAccreditations',
]);
const moment = inject('moment');
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const { t } = useI18n();

const times = reactive({
    checkIn: {
        date: '',
        index: 0,
        hour: 12,
    },
    checkOut: {
        date: '',
        index: 0,
        hour: 12,
    },
});

const tokensRequired = ref(0);
const enoughTokens = ref(true);
const bookingLength = ref(null);
const invalid = ref(false);
const durationInvalid = ref(false);
const invalidMessage = ref('');

function setDefaultDates() {
    console.log('setting default dates');
    times.checkIn.date = currentBooking.value.start.date;
    times.checkIn.index = currentBooking.value.start.index;
    times.checkIn.hour = currentBooking.value.start.hour;

    times.checkOut.date = currentBooking.value.end.date;
    times.checkOut.index = currentBooking.value.end.index;
    const startDate = moment(times.checkIn.date);
    const endDate = moment(times.checkOut.date);

    // This is to set the end hour to 19
    // but sometimes booking starts after 19 so we need
    // to account for this and not create any negative duration bookings
    if (startDate.isSame(endDate, 'date')) {
        // If same date, also check that start hour is less than 19
        if (currentBooking.value.start.hour < 19) {
            // if it is less than 19, set the end hour at 19
            times.checkOut.hour = 19;
        } else {
            // If start hour is >= 19, then set checkOut hour to whatever the original is
            times.checkOut.hour = currentBooking.value.end.hour;
        }
    } else if (currentBooking.value.end.hour > 19) {
        times.checkOut.hour = 19;
    } else {
        times.checkOut.hour = currentBooking.value.end.hour;
    }
}

function validate() {
    // reset invalid state
    invalid.value = false;
    invalidMessage.value = '';
    enoughTokens.value = true;

    const checkIn = moment.utc(times.checkIn.date);
    const checkOut = moment.utc(times.checkOut.date);

    checkIn.set({
        hour: times.checkIn.hour,
        minute: 0,
        second: 0,
        millisecond: 0,
    });
    checkOut.set({
        hour: times.checkOut.hour,
        minute: 0,
        second: 0,
        millisecond: 0,
    });

    const minStart = moment.utc(currentBooking.value.start.date);
    const maxEnd = moment.utc(currentBooking.value.end.date);

    // Calculate diff
    const diff = checkOut.diff(checkIn);
    const duration = moment.duration(diff);
    const durationInHours = duration.asHours(); // This would be used for validation as raw hours

    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, 'days'));

    const hours = duration.hours();
    duration.subtract(moment.duration(hours, 'hours'));

    // Create duration string (days, hours for display)
    var display = '';
    if (days) display = `${days} ${t('misc.days')}`;
    if (hours) display += ` ${hours} ${t('misc.hours')}`;
    if (display == '') {
        bookingLength.value = `0 ${t('misc.day')}`;
    } else {
        bookingLength.value = display;
    }

    if (currentAccreditation.value.tokens_split_monthly) {
        // Get current monthly accreditations
        const monthlyAccreditations =
            currentAccreditation.value.active_monthly_accreditations;

        // We want to calculate the tokens required for each month in the accreditation
        if (!checkIn.isSame(checkOut, 'month')) {
            // If month is not the same (multiple months), do calculation for multiple months
            const splittedMonths = splitDifferentMonths(checkIn, checkOut);
            splittedMonths.forEach((range, index) => {
                if (index == 0) {
                    // First month, checkIn
                    const tokens = calculateTokensRequiredMultipleMonths(
                        range,
                        'checkin'
                    );
                    const maIndex = monthlyAccreditations.findIndex((ma) =>
                        moment(ma.month_year_timestamp).isSame(
                            moment(range.start),
                            'month'
                        )
                    );
                    monthlyAccreditations[maIndex].tokensRequired = tokens;
                    if (
                        tokens > monthlyAccreditations[maIndex].current_tokens
                    ) {
                        enoughTokens.value = false;
                    }
                    console.log('First month', range, tokens);
                } else if (index == splittedMonths.length - 1) {
                    // Last month, checkOut
                    const tokens = calculateTokensRequiredMultipleMonths(
                        range,
                        'checkout'
                    );
                    const maIndex = monthlyAccreditations.findIndex((ma) =>
                        moment(ma.month_year_timestamp).isSame(
                            moment(range.start),
                            'month'
                        )
                    );
                    monthlyAccreditations[maIndex].tokensRequired = tokens;
                    if (
                        tokens > monthlyAccreditations[maIndex].current_tokens
                    ) {
                        enoughTokens.value = false;
                    }
                    console.log('Last month', range, tokens);
                } else {
                    // Middle
                    const tokens = calculateTokensRequiredMultipleMonths(
                        range,
                        'middle'
                    );
                    const maIndex = monthlyAccreditations.findIndex((ma) =>
                        moment(ma.month_year_timestamp).isSame(
                            moment(range.start),
                            'month'
                        )
                    );
                    monthlyAccreditations[maIndex].tokensRequired = tokens;
                    if (
                        tokens > monthlyAccreditations[maIndex].current_tokens
                    ) {
                        enoughTokens.value = false;
                    }
                    console.log('Middle month', range, tokens);
                }
            });
        } else {
            // Calculate tokens required for booking within the same month
            const maIndex = monthlyAccreditations.findIndex((ma) =>
                moment(ma.month_year_timestamp).isSame(checkIn, 'month')
            );
            const tokens = calculateTokensRequired(checkIn, checkOut);
            monthlyAccreditations[maIndex].tokensRequired = tokens;
            if (tokens > monthlyAccreditations[maIndex].current_tokens)
                enoughTokens.value = false;
        }

        console.log(monthlyAccreditations);
        emit('setCurrentMonthlyAccreditations', monthlyAccreditations);
    } else {
        // Months are not split

        const tokens = calculateTokensRequired(checkIn, checkOut);
        tokensRequired.value = tokens;
        if (tokens > currentAccreditation.value.current_tokens)
            enoughTokens.value = false;
        emit('setCurrentMonthlyAccreditations', []);
    }

    // Validation
    if (checkOut.isAfter(maxEnd)) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.checkOutAfterMaxEnd',
            { time: maxEnd.format('ddd Do MMMM HH:mm') }
        );
    } else if (
        // currentBookingType.value.booking_type_rushed == 'navigation' &&
        durationInHours > bookingSettings.value.maximum_length_hours &&
        bookingSettings.value.maximum_length_hours != 0 &&
        bookingSettings.value.maximum_length_hours != null
    ) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.durationMoreThanMaximum',
            {
                time: convertToDaysOrHours(
                    bookingSettings.value.maximum_length_hours
                ),
            }
        );
    } else if (
        // currentBookingType.value.booking_type_rushed == 'navigation' &&
        durationInHours < bookingSettings.value.minimum_length_hours
    ) {
        if (
            bookingSettings.value.maximum_length_hours == null ||
            bookingSettings.value.maximum_length_hours == '' ||
            bookingSettings.value.maximum_length_hours == 0
        ) {
            invalid.value = true;
            invalidMessage.value = t(
                'pages.bookNow.bookingDetails.errors.durationMoreThan',
                {
                    time: convertToDaysOrHours(
                        bookingSettings.value.minimum_length_hours
                    ),
                }
            );
        } else {
            invalid.value = true;
            invalidMessage.value = t(
                'pages.bookNow.bookingDetails.errors.durationLessThan',
                {
                    time: convertToDaysOrHours(
                        bookingSettings.value.minimum_length_hours
                    ),
                }
            );
        }
    } else if (diff == 0) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.inOutSame'
        );
    } else if (diff < 0) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.inAfterOut'
        );
    } else if (checkIn.isBefore(minStart)) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.inBeforeStart',
            { time: minStart.format('ddd Do MMMM HH:mm') }
        );
    } else if (times.hours == 0 && times.days == 0) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.atLeastOneHour'
        );
    } else if (!enoughTokens.value) {
        invalid.value = true;
        invalidMessage.value = t(
            'pages.bookNow.bookingDetails.errors.notEnoughTokens'
        );
    } else {
        invalid.value = false;
    }
}

function convertToDaysOrHours(hours) {
    let displayDays = null;
    let displayHours = null;

    if (hours % 24 == 0) {
        displayDays = hours / 24;
    } else {
        let rawDays = hours / 24;
        displayDays = Math.floor(rawDays);
        displayHours = Math.floor((rawDays - displayDays) * 24);
    }

    let display = '';
    if (displayDays) {
        display += `${displayDays} ${t('pages.bookNow.slotSelector.day')}`;
        if (displayDays != 1) {
            display += 's';
        }
    }
    if (displayDays && displayHours) {
        display += ' ';
    }
    if (displayHours) {
        display += `${displayHours} ${t('pages.bookNow.slotSelector.hour')}`;
        if (displayHours != 1) {
            display += 's';
        }
    }
    return display;
}

// Used to hide MonthlyToken rows that came before current month
// or Monthlytoken rows that aren't bookable yet because they
// aren't within the max_advance_booking_hours parameter
function isBeforeCurrentMonthOrNotInTheNextBookableMonths(date) {
    const currentMonth = moment().startOf('day').startOf('month');
    const toCheckMonth = moment.utc(date).tz('Europe/Paris', true);

    const maxMonth = moment()
        .add(bookingSettings.value.max_advance_booking_hours, 'hours')
        .endOf('month')
        .endOf('day');

    return (
        toCheckMonth.isBefore(currentMonth) || toCheckMonth.isAfter(maxMonth)
    );
}

watch(times, (newTimes) => {
    validate();
    emit('timesChanged', newTimes);
    console.log(newTimes);
});

watch(currentBooking, () => setDefaultDates());

watch(currentAccreditation, () => {
    setDefaultDates();
    validate();
});

onMounted(() => {
    setDefaultDates();
});
</script>
<style scoped>
.back-btn {
    padding: 10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: #a28e70;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1.4rem;
}

table {
    --bs-table-bg: transparent !important;
}
</style>
