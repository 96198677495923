<template>
    <div
        class="modal fade"
        id="errorFetchingSlotsModal"
        tabindex="-1"
        style="z-index: 2147483647"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <p style="font-weight: 500" class="mb-2">
                        {{
                            t(
                                'pages.bookNow.slotSelector.displayAvailableSlotsErrorTitle'
                            )
                        }}
                    </p>
                    {{
                        t(
                            'pages.bookNow.slotSelector.displayAvailableSlotsError'
                        )
                    }}<br />
                    {{ t('pages.bookNow.slotSelector.sorryForInconvenience') }}
                </div>
                <a
                    class="border-top text-center py-2 tap-react rounded-bottom"
                    data-bs-dismiss="modal"
                    style="font-weight: 600"
                >
                    OK
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
