<template>
    <div
        class="modal fade"
        id="bookedMessageModal"
        tabindex="-1"
        style="z-index: 1000000"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div style="font-weight: 500">
                        {{ t('pages.bookNow.bookingConfirmation.success') }}
                    </div>
                    {{ t('pages.bookNow.bookingConfirmation.bookingMade') }}
                </div>
                <div class="border-top">
                    <a
                        data-bs-dismiss="modal"
                        @click="router.push('/bookings')"
                        class="w-100"
                        style="font-weight: 600"
                    >
                        <div
                            class="px-2 py-2 text-center tap-react rounded-bottom"
                        >
                            {{ t('misc.modal.done') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="bookErrorModal"
        tabindex="-1"
        style="z-index: 1000000"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div style="font-weight: 500">
                        {{ t('pages.bookNow.bookingConfirmation.error') }}
                    </div>
                    <div v-html="bookingErrorMessage"></div>
                </div>
                <div class="border-top">
                    <a
                        data-bs-dismiss="modal"
                        @click="
                            router.push('/yachts/' + currentYacht.identity.id)
                        "
                        class="w-100"
                        style="font-weight: 600"
                    >
                        <div
                            class="px-2 py-2 text-center tap-react rounded-bottom"
                        >
                            {{ t('misc.modal.done') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <a
                href="#"
                @click="emit('pageChanged', 'CheckInOut')"
                class="back-btn rounded"
            >
                <i class="fa fa-chevron-left"></i>
            </a>
            <h1 class="m-0 text-light">
                {{ t('pages.bookNow.bookingConfirmation.confirmBooking') }}
            </h1>
        </div>
        <div class="card p-3 shadow-sm mb-3">
            <YachtItem class="mb-3" :yacht="currentYacht" style="width: 100%" />
            <div class="d-flex flex-row mb-0" style="gap: 1rem">
                <div
                    style="font-weight: 500; gap: 0.25rem"
                    class="d-flex flex-column"
                >
                    <div>Check-in</div>
                    <div>Check-out</div>
                    <div>
                        {{ t('pages.bookNow.bookingConfirmation.duration') }}
                    </div>
                    <div>
                        {{
                            t(
                                'pages.bookNow.bookingConfirmation.tokensRequired'
                            )
                        }}
                    </div>
                </div>
                <div class="d-flex flex-column" style="gap: 0.25rem">
                    <div>
                        {{ checkInFormatted }}
                    </div>
                    <div>
                        {{ checkOutFormatted }}
                    </div>
                    <div>
                        {{ bookingLength }}
                    </div>
                    <div>
                        {{ tokensRequired }}
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow-sm p-3 mb-3">
            <h5 class="mb-3">
                {{ t('pages.bookNow.bookingConfirmation.notes') }}
            </h5>
            <textarea
                class="form-control"
                style="resize: none; height: 5rem"
                :placeholder="t('pages.bookNow.bookingConfirmation.notes')"
                v-model="notes"
            ></textarea>
        </div>
        <button
            :class="[processingBooking ? 'd-none' : '']"
            @click="bookNow"
            ref="confirmBookingButton"
            class="btn btn-primary w-100"
        >
            <i class="fa fa-check me-1"></i
            >{{ t('pages.bookNow.bookingConfirmation.confirmBooking') }}
        </button>
        <div
            :class="[processingBooking ? '' : 'd-none']"
            class="d-flex flex-row justify-content-center align-items-center"
            style="gap: 0.5rem"
        >
            <img
                src="@/assets/loading-icon.gif"
                style="width: 1.5rem"
                alt="Loading"
            />
            {{ t('pages.bookNow.bookingConfirmation.processing') }}
        </div>
    </div>
    <a
        href="#"
        ref="toggleBookedMessageModal"
        @click="showBookedMessage"
        data-bs-toggle="modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-bs-target="#bookedMessageModal"
    ></a>
    <a
        href="#"
        ref="toggleBookingErrorModal"
        class="d-none"
        data-bs-toggle="modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-bs-target="#bookErrorModal"
    ></a>
</template>

<script setup>
import { calculateTokensRequired } from '@/helpers';
import {
    defineEmits,
    defineProps,
    ref,
    toRefs,
    inject,
    watch,
    onMounted,
} from 'vue';
import axios from 'axios';
import YachtItem from '@/components/YachtItem.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const props = defineProps([
    'currentYacht',
    'currentTimes',
    'currentMonthlyAccreditations',
]);

const { currentYacht, currentTimes, currentMonthlyAccreditations } =
    toRefs(props);
const emit = defineEmits(['pageChanged']);

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const { t } = useI18n();
const router = useRouter();
const moment = inject('moment');

const notes = ref(null);
const tokensRequired = ref(null);
const bookingLength = ref(null);

const checkInFormatted = ref(null);
const checkOutFormatted = ref(null);
const bookingErrorMessage = ref('');
const confirmBookingButton = ref(null);
const toggleBookingErrorModal = ref(null);
const toggleBookedMessageModal = ref(null);
const processingBooking = ref(false);

async function bookNow() {
    console.log(currentMonthlyAccreditations.value);
    if (!processingBooking.value) {
        processingBooking.value = true;
        try {
            // Do a last check before we send off the booking
            const lastCheckRes = await axios.post('schedules/lastcheck', {
                start: {
                    index: currentTimes.value.checkIn.index,
                    hour: currentTimes.value.checkIn.hour,
                },
                end: {
                    index: currentTimes.value.checkOut.index,
                    hour: currentTimes.value.checkOut.hour,
                },
                boatId: currentYacht.value.itself.id,
                required: tokensRequired.value,
            });

            if (lastCheckRes.data.status == true) {
                if (lastCheckRes.data.enoughTokens) {
                    confirmBookingButton.value.classList.add('d-none');
                    const data = {
                        boat_id: currentYacht.value.itself.id,
                        notes: notes.value,
                        start_date: {
                            dayIndex: currentTimes.value.checkIn.index,
                            hour: currentTimes.value.checkIn.hour,
                        },
                        end_date: {
                            dayIndex: currentTimes.value.checkOut.index,
                            hour: currentTimes.value.checkOut.hour,
                        },
                        monthly_accreditations:
                            currentMonthlyAccreditations.value,
                    };
                    await axios.post('schedule/make-booking', data);
                    toggleBookedMessageModal.value.click();
                    processingBooking.value = false;
                } else {
                    processingBooking.value = false;
                    bookingErrorMessage.value = t(
                        'pages.bookNow.bookingConfirmation.notEnoughTokens'
                    );
                    toggleBookingErrorModal.value.click();
                }
            } else {
                processingBooking.value = false;
                bookingErrorMessage.value = t(
                    'pages.bookNow.bookingConfirmation.anotherBookingMade'
                );
                toggleBookingErrorModal.value.click();
            }
        } catch (e) {
            console.error(e);
            processingBooking.value = false;
            bookingErrorMessage.value = t(
                'pages.bookNow.bookingConfirmation.bookingError'
            );
            toggleBookingErrorModal.value.click();
        }
    }
}

function updateCurrentTimes() {
    let start = moment.utc(currentTimes.value.checkIn.date);
    start.set('hour', currentTimes.value.checkIn.hour);

    let end = moment.utc(currentTimes.value.checkOut.date);
    end.set('hour', currentTimes.value.checkOut.hour);

    currentTimes.value.checkIn.currentTimestamp = start.toISOString();
    currentTimes.value.checkOut.currentTimestamp = end.toISOString();

    checkInFormatted.value = start.format('ddd Do MMM HH:00');
    checkOutFormatted.value = end.format('ddd Do MMM HH:00');

    // Calculate diff
    const diff = end.diff(start);
    const duration = moment.duration(diff);
    // const durationInHours = duration.asHours(); // This would be used to calculate the tokens required

    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, 'days'));

    const hours = duration.hours();
    duration.subtract(moment.duration(hours, 'hours'));

    // Create duration string (days, hours for display)
    var display = '';
    if (days) display = `${days} ${t('misc.days')}`;
    if (hours) display += ` ${hours} ${t('misc.hours')}`;
    if (display == '') {
        bookingLength.value = `0 ${t('misc.day')}`;
    } else {
        bookingLength.value = display;
    }

    tokensRequired.value = calculateTokensRequired(start, end);
}

watch(props.currentTimes, () => updateCurrentTimes());
onMounted(() => updateCurrentTimes());
</script>

<style scoped>
.back-btn {
    padding: 10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: #a28e70;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1.4rem;
}
</style>
