<template>
    <div>
        <ErrorFetchingSlotsModal />
        <div
            v-if="currentYacht"
            class="container"
            :style="{
                'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
                'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
            }"
        >
            <div>
                <div
                    class="d-flex flex-row align-items-center mb-3"
                    style="gap: 0px"
                >
                    <BackButton />
                    <h1 class="m-0 text-light">
                        <span v-if="currentYacht.brand">
                            {{
                                t('pages.bookNow.slotSelector.title', {
                                    name:
                                        currentYacht.brand.name +
                                        ' ' +
                                        currentYacht.identity.name,
                                })
                            }}
                        </span>
                        <span v-else-if="currentYacht.identity">
                            {{
                                t('pages.bookNow.slotSelector.title', {
                                    name: currentYacht.identity.name,
                                })
                            }}
                        </span>
                    </h1>
                </div>
                <div
                    v-if="currentAccreditation.expired"
                    class="card border p-3"
                >
                    <h5>
                        {{
                            t('pages.bookNow.slotSelector.subscriptionExpired')
                        }}
                    </h5>
                    {{
                        t('pages.bookNow.slotSelector.subscriptionExpiredDesc')
                    }}
                    <router-link
                        to="/more/contact-support"
                        class="btn btn-primary mt-3"
                        >{{
                            t('pages.bookNow.slotSelector.contactSupport')
                        }}</router-link
                    >
                </div>
                <div
                    v-else-if="
                        currentAccreditation.subscription_active ||
                        currentAccreditationHasntStartedButAllowBooking
                    "
                >
                    <div class="card p-0 shadow-sm">
                        <div class="p-3">
                            <h5
                                v-if="
                                    bookingSettings.max_advance_booking_hours !=
                                    0
                                "
                                class="mb-2"
                            >
                                {{
                                    t(
                                        'pages.bookNow.slotSelector.slotsAvailableInTheNext',
                                        {
                                            daysOrHours: convertToDaysOrHours(
                                                bookingSettings.max_advance_booking_hours
                                            ),
                                            start: moment
                                                .utc(currentAccreditation.start)
                                                .format('MMM, D, YYYY'),
                                            end: moment
                                                .utc(currentAccreditation.end)
                                                .format('MMM, D, YYYY'),
                                        }
                                    )
                                }}
                            </h5>
                            <p class="mb-0 text-muted">
                                {{
                                    t(
                                        'pages.bookNow.slotSelector.yourSubscriptionFrom',
                                        {
                                            start: moment
                                                .utc(currentAccreditation.start)
                                                .format('MMM, D, YYYY'),
                                            end: moment
                                                .utc(currentAccreditation.end)
                                                .format('MMM, D, YYYY'),
                                        }
                                    )
                                }}
                            </p>
                            <p class="text-muted m-0">
                                <span
                                    v-if="
                                        currentAccreditationHasntStartedButAllowBooking
                                    "
                                >
                                    {{
                                        t(
                                            'pages.bookNow.slotSelector.accreditationHasntStartedButCanBook'
                                        )
                                    }}
                                    ({{
                                        moment(
                                            currentAccreditation.start
                                        ).format('ddd, MMM D, YYYY')
                                    }}).
                                </span>
                                {{
                                    t('pages.bookNow.slotSelector.explanation')
                                }}
                            </p>
                        </div>

                        <div
                            v-if="!firstLoadSlotsDone && loadingSlots"
                            style="height: 157px"
                            class="d-flex flex-row justify-content-center align-items-center"
                        >
                            <img
                                src="@/assets/loading-icon.gif"
                                style="width: 1.2rem"
                                alt="Loading"
                            />
                        </div>
                        <div v-else-if="slots.length != 0">
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <div
                                    style="max-width: 500px"
                                    class="flex-grow-1"
                                >
                                    <DatePicker
                                        borderless
                                        expanded
                                        timezone="Europe/Paris"
                                        :disabled-dates="calendarDisabledDays"
                                        :attributes="calendarAttributes"
                                        :locale="i18n.locale.value"
                                        :min-date="calendarMinDate"
                                        :max-date="calendarMaxDate"
                                        :initial-page="initialPage"
                                        v-model.range="selectedDateRange"
                                    />
                                    <div
                                        class="pb-3"
                                        style="padding: 1rem; padding-top: 0"
                                    >
                                        <div
                                            class="d-flex align-items-center justify-content-start"
                                            style="gap: 1.5rem"
                                        >
                                            <div
                                                class="d-flex align-items-center text-success"
                                                style="
                                                    gap: 0.5rem;
                                                    font-weight: 500;
                                                "
                                            >
                                                <div
                                                    style="
                                                        width: 8px;
                                                        height: 8px;
                                                        border-radius: 100%;
                                                    "
                                                    class="bg-success"
                                                ></div>
                                                {{
                                                    t(
                                                        'pages.bookNow.slotSelector.availableOnly'
                                                    )
                                                }}
                                            </div>
                                            <div
                                                class="d-flex align-items-center text-danger"
                                                style="
                                                    gap: 0.5rem;
                                                    font-weight: 500;
                                                "
                                            >
                                                <div
                                                    style="
                                                        width: 8px;
                                                        height: 8px;
                                                        border-radius: 100%;
                                                    "
                                                    class="bg-danger"
                                                ></div>
                                                {{
                                                    t(
                                                        'pages.bookNow.slotSelector.booked'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="p-3 pt-0"
                                v-if="
                                    selectedDateRange.start &&
                                    selectedDateRange.end
                                "
                            >
                                <button
                                    class="btn btn-primary w-100"
                                    @click="selectBooking"
                                >
                                    {{ t('pages.bookNow.slotSelector.book') }}
                                    {{
                                        moment(selectedDateRange.start).format(
                                            'MMMM Do'
                                        )
                                    }}
                                    -
                                    {{
                                        moment(selectedDateRange.end).format(
                                            'MMM Do'
                                        )
                                    }}
                                </button>
                            </div>
                        </div>
                        <div v-else class="px-3 py-3 text-danger">
                            {{ t('pages.bookNow.slotSelector.noSlotsFound') }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="!currentAccreditation.subscription_started"
                    class="card border p-3"
                >
                    <h5>
                        {{
                            t(
                                'pages.bookNow.slotSelector.earliestSubscriptionHasntStarted'
                            )
                        }}
                    </h5>
                    {{
                        t(
                            'pages.bookNow.slotSelector.itIsScheduledToStartedOn',
                            {
                                date: moment(currentAccreditation.start).format(
                                    'ddd, MMM, D, YYYY HH:mm'
                                ),
                            }
                        )
                    }}
                    <br />
                    {{ t('pages.bookNow.slotSelector.ifYouHaveAnyQuestions') }}
                    <router-link
                        to="/more/contact-support"
                        class="btn btn-primary mt-3"
                        >{{
                            t('pages.bookNow.slotSelector.contactSupport')
                        }}</router-link
                    >
                </div>
            </div>
            <button
                class="d-none"
                ref="toggleErrorFetchingSlotsModal"
                data-bs-toggle="modal"
                data-bs-target="#errorFetchingSlotsModal"
            ></button>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import {
    inject,
    onMounted,
    ref,
    toRefs,
    defineProps,
    defineEmits,
    reactive,
    onBeforeUnmount,
    computed,
} from 'vue';
import axios from 'axios';
import BackButton from '@/components/BackButton';
import ErrorFetchingSlotsModal from './ErrorFetchingSlotsModal';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
// import BookingTimeline from '@/components/BookingTimeline';

const props = defineProps([
    'currentYacht',
    'currentAccreditation',
    'bookingSettings',
]);
const emit = defineEmits([
    'pageChanged',
    'selectedBooking',
    'selectedDateRange',
]);
const i18n = useI18n();
const { t } = useI18n();
const moment = inject('moment');
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const toggleErrorFetchingSlotsModal = ref(null);

let fetchSlotsInterval = null;

const { currentYacht, currentAccreditation, bookingSettings } = toRefs(props);

const selectedDateRange = ref({
    start: null,
    end: null,
});
const firstLoadSlotsDone = ref(false);
const loadingSlots = ref(true);
const slots = ref([]);
let initialPage = reactive({});

let calendarAttributes = reactive([]);
let calendarDisabledDays = reactive([]);

const currentAccreditationHasntStartedButAllowBooking = computed(
    () =>
        !currentAccreditation.value.subscription_started &&
        currentAccreditation.value.allow_early_booking
);

function generateInverseRanges(availableRanges) {
    const startDate = moment().startOf('day');
    const endDate = moment().add(60, 'days');

    let inverseRanges = [];
    let currentStart = startDate;

    // Iterate through each available range
    for (let i = 0; i < availableRanges.length; i++) {
        const range = availableRanges[i];

        // Check if there's a gap between the current start date and the start date of the current range
        if (currentStart.isBefore(range.start)) {
            inverseRanges.push({
                start: currentStart,
                end: range.start.clone().subtract(1, 'day'),
            });
        }

        // Update the current start date to the end date of the current range
        currentStart = range.end.clone().add(1, 'day');
    }

    // Check if there's a gap between the last available end date and the given end date
    if (currentStart.isBefore(endDate)) {
        inverseRanges.push({ start: currentStart, end: endDate });
    }

    return inverseRanges;
}

// Because all the slots returned are available days, we need
// to create an inverse of the days that are unavailable. These
// are the days that aren't in available days.
function makeNegativeMask(slots) {
    const transformedSlots = [];
    slots.forEach((slot) => {
        transformedSlots.push({
            start: moment
                .utc(slot.start.date)
                .startOf('day')
                .tz('Europe/Paris', true),
            end: moment
                .utc(slot.end.date)
                .startOf('day')
                .tz('Europe/Paris', true),
        });
    });

    const negatives = generateInverseRanges(transformedSlots);
    const negativesDateOnly = [];
    negatives.forEach((negative) => {
        negativesDateOnly.push({
            start: negative.start.format(),
            end: negative.end.format(),
        });
    });

    return negativesDateOnly;
}

async function fetchSlots() {
    if (!firstLoadSlotsDone.value) loadingSlots.value = true;
    axios
        .post('/schedule/available-slots', {
            boat_id: currentYacht.value.itself.id,
        })
        .then((res) => {
            slots.value = res.data;

            calendarAttributes = [];
            calendarDisabledDays = [];

            const negativeMasks = makeNegativeMask(slots.value);
            negativeMasks.forEach((mask) => {
                let dates = {};

                const startDate = moment(mask.start).startOf('day');
                const endDate = moment(mask.end).startOf('day');

                if (
                    moment.utc(mask.start).isSame(moment.utc(mask.end), 'date')
                ) {
                    dates = mask.start;
                } else {
                    dates = {
                        start: startDate.format(),
                        end: endDate.format(),
                    };
                }

                calendarAttributes.push({
                    highlight: {
                        color: 'red',
                        fillMode: 'light',
                    },
                    dates,
                    order: 1,
                });

                calendarDisabledDays.push({
                    start: mask.start,
                    end: mask.end,
                });
            });

            slots.value.forEach((slot) => {
                let dates = {};
                const startDate = moment
                    .utc(slot.start.date)
                    .startOf('day')
                    .tz('Europe/Paris', true);

                const endDate = moment
                    .utc(slot.end.date)
                    .startOf('day')
                    .tz('Europe/Paris', true);

                if (startDate.isSame(endDate, 'date')) {
                    dates = startDate.format();
                } else {
                    dates = {
                        start: startDate.format(),
                        end: endDate.format(),
                    };
                }

                calendarAttributes.push({
                    highlight: {
                        color: 'green',
                        fillMode: 'light',
                    },
                    dates,
                    order: 0,
                });
            });

            if (!firstLoadSlotsDone.value) firstLoadSlotsDone.value = true;
            loadingSlots.value = false;
        })
        .catch((e) => {
            console.error(e);
            slots.value = [];
            toggleErrorFetchingSlotsModal.value.click();
            if (!firstLoadSlotsDone.value) firstLoadSlotsDone.value = true;
            loadingSlots.value = false;
        });
}

function convertToDaysOrHours(hours) {
    let displayDays = null;
    let displayHours = null;

    if (hours % 24 == 0) {
        displayDays = hours / 24;
    } else {
        let rawDays = hours / 24;
        displayDays = Math.floor(rawDays);
        displayHours = Math.floor((rawDays - displayDays) * 24);
    }

    let display = '';
    if (displayDays) {
        display += `${displayDays} ${t('pages.bookNow.slotSelector.day')}`;
        if (displayDays != 1) {
            display += 's ';
        }
        display += ' ';
    }
    if (displayHours) {
        display += `${displayHours} ${t('pages.bookNow.slotSelector.hour')}`;
        if (displayHours != 1) {
            display += 's';
        }
    }
    return display;
}

function findSlot() {
    return JSON.parse(
        JSON.stringify(
            slots.value.find((slot) => {
                const slotStart = moment
                    .utc(slot.start.date)
                    .tz('Europe/Paris', true)
                    .startOf('day');

                const slotEnd = moment
                    .utc(slot.end.date)
                    .tz('Europe/Paris', true)
                    .startOf('day');

                const dateRangeStart = moment(selectedDateRange.value.start);
                const dateRangeEnd = moment(selectedDateRange.value.end);

                return (
                    slotStart.isSameOrBefore(dateRangeStart) &&
                    slotEnd.isSameOrAfter(dateRangeEnd)
                );
            })
        )
    );
}

function selectBooking() {
    try {
        // Get whatever slot is in range of the selected start and end
        const selectedSlot = findSlot();

        const bookingStart = moment
            .utc(selectedSlot.start.date)
            .tz('Europe/Paris', true);

        const bookingEnd = moment
            .utc(selectedSlot.end.date)
            .tz('Europe/Paris', true);

        const rangeStart = moment(selectedDateRange.value.start);
        const rangeEnd = moment(selectedDateRange.value.end);

        const transformed = {
            start: {
                date: null,
                index: null,
            },
            range: [],
            end: {
                date: null,
                index: null,
            },
        };

        if (rangeStart.isSame(bookingStart, 'date')) {
            transformed.start = selectedSlot.start;
            console.log('Not transforming startSlot');
        } else {
            const startSlot = selectedSlot.range.find(
                (day) =>
                    day.date ==
                    rangeStart.startOf('day').format('YYYY-MM-DD 00:00:00')
            );
            transformed.start.date = moment
                .utc(startSlot.date)
                .startOf('day')
                .set({ hour: 9 })
                .format('YYYY-MM-DD HH:mm:ss');
            transformed.start.index = startSlot.index;
            transformed.start.hour = 9;
            console.log('Transforming startSlot to start at 09:00');
        }

        if (rangeEnd.isSame(bookingEnd, 'date')) {
            transformed.end = selectedSlot.end;
            console.log('Not transforming endSlot');
        } else {
            const endSlot = selectedSlot.range.find(
                (day) =>
                    day.date ==
                    rangeEnd.startOf('day').format('YYYY-MM-DD 00:00:00')
            );
            transformed.end.date = moment
                .utc(endSlot.date)
                .endOf('day')
                .set({ hour: 23 })
                .format('YYYY-MM-DD HH:mm:ss');
            transformed.end.index = endSlot.index;
            transformed.end.hour = 23;
            console.log('Transforming endSlot to end at 23:00');
        }

        // Create range
        transformed.range = [];
        selectedSlot.range.forEach((date) => {
            if (
                transformed.end.index <= date.index ||
                transformed.start.index >= date.index
            )
                transformed.range.push(date);
        });

        if (
            !transformed.start.date ||
            !transformed.start.index ||
            !transformed.end.date ||
            !transformed.end.index
        ) {
            throw 'One of transformed is null';
        } else {
            emit('pageChanged', 'CheckInOut');
            emit('selectedBooking', transformed);
            emit('selectedDateRange', selectedDateRange);
        }
    } catch (e) {
        console.error(e);
        alert(t('pages.bookNow.slotSelector.bookingCantBeMade'));
    }
}

const calendarMaxDate = computed(() => {
    // DISABLED: Max date is whenever the accreditation ends
    // const currentAccreditationEnd = moment
    //     .utc(currentAccreditation.value.end)
    //     .tz('Europe/Paris', true);

    // return {
    //     day: currentAccreditationEnd.format('DD'),
    //     month: currentAccreditationEnd.format('MM'),
    //     year: currentAccreditationEnd.format('YYYY'),
    // };

    // Max date is actually whenever the accreditation ends
    // If early booking is allowed
    if (currentAccreditation.value.allow_early_booking) {
        console.log(currentAccreditation.value.end);
        return moment
            .utc(currentAccreditation.value.end)
            .tz('Europe/Paris', true)
            .format();
    }

    // If early booking is not allowed, it's max_advance
    return moment()
        .add(bookingSettings.value.max_advance_booking_hours, 'hours')
        .format();
});

const calendarMinDate = computed(() => {
    const currentTime = moment();

    if (currentAccreditationHasntStartedButAllowBooking.value) {
        const currentAccreditationStart = moment
            .utc(currentAccreditation.value.start)
            .tz('Europe/Paris', true);

        return {
            day: currentAccreditationStart.format('DD'),
            month: currentAccreditationStart.format('MM'),
            year: currentAccreditationStart.format('YYYY'),
        };
    } else {
        return {
            day: currentTime.format('DD'),
            month: currentTime.format('MM'),
            year: currentTime.format('YYYY'),
        };
    }
});

onMounted(() => {
    // Update slots every second
    fetchSlots();
    fetchSlotsInterval = setInterval(() => {
        fetchSlots();
    }, 10000);
    clearInterval(fetchSlotsInterval);

    initialPage = {
        day: moment().format('DD'),
        month: moment().format('MM'),
        year: moment().format('YYYY'),
    };

    console.log(currentAccreditation.value.end);
    console.log('calendarMaxDate', calendarMaxDate.value);
});

onBeforeUnmount(() => {
    clearInterval(fetchSlotsInterval);
});
</script>

<style scoped>
.check-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
}
</style>
<style>
.vc-weeks {
    padding: 1rem;
}

.vc-header {
    padding-left: 1rem;
    padding-right: 1rem;
}

.vc-attr.vc-highlight-content-light.vc-red {
    color: var(--vc-accent-900) !important;
}
</style>
