// checkIn and checkOut are moment objects
export function calculateTokensRequired(checkIn, checkOut) {
    let tokens = 0;

    // if checkIn is smaller than checkOut, it is invalid so return 0 tokens required
    if (checkOut.diff(checkIn) <= 0) return 0;

    // check if single day
    if (checkIn.isSame(checkOut, 'date')) {
        tokens += 1;
        if (checkOut.hour() > 21) tokens += 1;
    } else {
        // check if span multiple days
        // get difference between two dates

        // get number of days
        // look at only the dates
        // const startDateNumber = parseInt(checkIn.format('DD'));
        // const endDateNumber = parseInt(checkOut.format('DD'));

        // const diffBetweenDates = endDateNumber - startDateNumber + 1;
        const diffBetweenDates = checkOut.diff(checkIn, 'days') + 1;

        if (checkIn.hour() >= 21) tokens += 1;

        // deal with first day by itself
        // it's gonna be one token anyways
        tokens += diffBetweenDates;

        // only check the time of the last day
        // check if last day > 21
        if (checkOut.hour() > 21) tokens += 1;
    }

    return tokens;
}

export function splitDifferentMonths(checkIn, checkOut) {
    const ranges = [];
    let currentDate = checkIn.clone().startOf('day');
    let currentMonth = checkIn.clone().startOf('day');
    let currentRange = { start: checkIn.clone(), end: null };

    while (currentDate.isBefore(checkOut)) {
        while (
            currentDate.isSame(currentMonth, 'month') &&
            currentDate.isBefore(checkOut)
        ) {
            currentRange.end = currentDate.clone().endOf('day');
            currentDate.add(1, 'day');
        }

        // Set to middle of day
        currentDate.startOf('day');
        currentMonth = currentDate.clone();

        if (currentRange.end.isSame(checkOut, 'date')) {
            currentRange.end = checkOut.clone();
        }

        ranges.push({
            start: currentRange.start.clone(),
            end: currentRange.end.clone(),
        });
        currentRange.start = currentDate.clone();
        // currentDate.add(1, 'day');

        // if (currentDate.isSame(checkOut, 'date')) {
        //     currentRange.end = checkOut.clone();
        // }
    }

    return ranges;
}

export function calculateTokensRequiredMultipleMonths(range, inOutOrMiddle) {
    let tokensForThisRange = 0;
    const diffBetweenDates = range.end.diff(range.start, 'days') + 1;
    tokensForThisRange += diffBetweenDates;

    if (inOutOrMiddle == 'checkin') {
        if (range.start.hour() >= 21) tokensForThisRange += 1;
    } else if (inOutOrMiddle == 'checkout') {
        if (range.end.hour() > 21) tokensForThisRange += 1;
    } else if (inOutOrMiddle == 'middle') {
        // Calculation for this already done
    } else {
        throw 'Unknown inOutOrMiddle';
    }

    return tokensForThisRange;
}
