<template>
    <div v-if="!settings.disable_bookings">
        <div v-if="bookingSettings">
            <div v-if="currentYacht">
                <keep-alive>
                    <SlotSelector
                        @page-changed="(page) => changePage(page)"
                        @selected-booking="
                            (booking) => (currentBooking = booking)
                        "
                        @selected-date-range="
                            (dateRange) => (currentDateRange = dateRange)
                        "
                        :current-yacht="currentYacht"
                        :booking-settings="bookingSettings"
                        :current-accreditation="currentAccreditation"
                        v-if="currentPage == 'SlotSelector'"
                    />
                </keep-alive>
                <keep-alive>
                    <CheckInOut
                        @page-changed="(page) => changePage(page)"
                        @times-changed="(times) => timesChanged(times)"
                        @set-current-monthly-accreditations="
                            (mas) => (currentMonthlyAccreditations = mas)
                        "
                        :current-yacht="currentYacht"
                        :current-booking="currentBooking"
                        :current-accreditation="currentAccreditation"
                        :booking-settings="bookingSettings"
                        v-if="currentPage == 'CheckInOut'"
                    />
                </keep-alive>
                <keep-alive>
                    <BookingConfirmation
                        @page-changed="(page) => changePage(page)"
                        :currentYacht="currentYacht"
                        :currentTimes="currentTimes"
                        :currentMonthlyAccreditations="
                            currentMonthlyAccreditations
                        "
                        v-if="currentPage == 'BookingConfirmation'"
                    />
                </keep-alive>
            </div>
            <div
                v-else
                class="container"
                :style="{
                    'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
                    'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
                }"
            >
                <div
                    class="d-flex flex-row align-items-center mb-3"
                    style="gap: 0px"
                >
                    <BackButton />
                    <h1 class="m-0 text-light">
                        {{ t('pages.viewYacht.notFound') }}
                    </h1>
                </div>
                <div class="card p-3">
                    <h5>{{ t('pages.viewYacht.yachtNotFound') }}</h5>
                    <p>
                        {{ t('pages.viewYacht.notFoundDesc') }}
                    </p>
                    <button @click="router.back()" class="btn btn-primary">
                        {{ t('misc.goBack') }}
                    </button>
                </div>
            </div>
        </div>
        <div
            class="container"
            :style="{
                'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
                'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
            }"
            v-else
        >
            <div
                class="d-flex flex-row align-items-center mb-3"
                style="gap: 0px"
            >
                <BackButton />
                <h1 class="m-0 text-light">
                    {{
                        t('pages.bookNow.slotSelector.title', {
                            name: '',
                        })
                    }}
                </h1>
            </div>
            <div
                class="card d-flex justify-content-center align-items-center"
                style="height: 111px"
            >
                <img src="@/assets/loading-icon.gif" style="width: 1.5rem" />
            </div>
        </div>
    </div>
    <div
        v-else
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">Maintenance</h1>
        </div>
        <div class="card p-3 shadow-sm mb-3">
            <h5>Undergoing maintenance</h5>
            <p>
                We're sorry, but we're currently performing maintenance on the
                booking system to provide the best experience possible. You
                cannot make a booking right now. We're sorry for any
                inconvenience and we will be back as soon as possible.
            </p>

            <a href="#" class="btn btn-primary" onclick="location.reload()"
                >Check again</a
            >
        </div>
    </div>
</template>

<script setup>
/**
 * TODO: Refactor
 * Total spaghetti code state management mayhem
 * This also applies to the child components
 */
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user-store';
import { useYachtsStore } from '@/stores/yachts-store';
import { onMounted, ref, inject, watch, computed } from 'vue';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
import { useBookingSettingsStore } from '@/stores/booking-settings-store';

import CheckInOut from './CheckInOut.vue';
import SlotSelector from './SlotSelector.vue';
import BackButton from '@/components/BackButton';
import BookingConfirmation from './BookingConfirmation.vue';

const { t } = useI18n();
const router = useRouter();

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const bookingSettingsStore = useBookingSettingsStore();
const { bookingSettings } = storeToRefs(bookingSettingsStore);

const remoteSettingsStore = useRemoteSettingsStore();
const { settings } = storeToRefs(remoteSettingsStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const yachtsStore = useYachtsStore();
const { yachts } = storeToRefs(yachtsStore);

const route = useRoute();

const currentPage = ref('SlotSelector');
const currentBookingType = ref(null);
const currentParameter = ref(null);
const currentBooking = ref(null);
const currentTimes = ref(null);
const currentDateRange = ref(null);

/**
 * This is sent to the server for processing, which
 * is a security risk, but I don't have time to implement
 * the same algorithms in PHP as well for server-side
 * validation/generation.
 *
 * Dynamically set by CheckInOut during validation
 * and passed onto BookingConfirmation via props
 */
const currentMonthlyAccreditations = ref(null);

/**
 * TODO: Refactor
 * We're doing all this fetchSettings just in case the service becomes
 * unavailable. There's a more efficient way to do this, needs a refactor
 * sometime in the future.
 */

function changePage(page) {
    userStore.fetchUser(); // Update tokens
    remoteSettingsStore.fetchSettings(); // Update remote settings (like disable bookings)
    bookingSettingsStore.fetch(); // Update booking settings (booking parameters, i.e. max_advance)
    currentPage.value = page;
}

function timesChanged(times) {
    currentTimes.value = times;
}

const currentAccreditation = computed(() => {
    if (!currentYacht.value) return;
    return user.value.accreditations.find(
        (accreditation) => accreditation.boat_id == currentYacht.value.itself.id
    );
});

watch(
    currentBookingType,
    () => userStore.fetchUser() // Update tokens
);

watch(currentParameter, () => userStore.fetchUser()); // update tokens

const currentYacht = computed(() =>
    yachts.value.find((yacht) => yacht.itself.id == route.params.id)
);

onMounted(() => {
    remoteSettingsStore.fetchSettings(); // Update remote settings (like disable bookings)
    bookingSettingsStore.fetch(); // Update booking settings (booking parameters, i.e. max_advance)
});
</script>
