<template>
    <div class="d-flex flex-row">
        <!-- Icons -->
        <div
            class="d-flex flex-column text-muted align-items-center"
            style="gap: 1.3rem; z-index: 100"
        >
            <i class="dot-icon fa fa-circle py-1" style="background: white"></i>
            <i
                class="dot-icon fa fa-circle py-1 pb-2"
                style="background: white"
            ></i>
        </div>

        <!-- Content -->
        <div
            class="d-flex flex-column align-items-start"
            style="
                gap: 0.5rem;
                padding-left: 1rem;
                margin-left: -0.35rem;
                border-left: 2px solid #cbcbcb;
            "
        >
            <div>
                {{ moment.utc(booking.start.date).format('ddd Do MMMM HH:mm') }}
            </div>
            <div>
                {{ moment.utc(booking.end.date).format('ddd Do MMMM HH:mm') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { toRefs, defineProps, inject } from 'vue';
import { getLocale } from '@/getLocale';
const moment = inject('moment');
const props = defineProps(['booking']);
const { booking } = toRefs(props);
moment.locale(getLocale());
</script>

<style scoped>
.line::after {
    content: '';
    background-color: black;
    width: 1px;
    height: 50px;
    position: absolute;
    left: 0;
}

.line-container {
    position: relative;
}

.arrow-icon {
    font-size: 0.8rem;
}

.dot-icon {
    font-size: 0.5rem;
}
</style>
